<template>
    <div class="d-flex w-100 flex-wrap">
        <div class="d-flex flex-column">
            <div class="d-flex mb-3">
                <CustomInputPhone
                    v-model:value="phone"
                    :label="t('cart.guest_phone')"
                    :error="props.errors?.phone"
                    :disabled="props.isDisabled"
                    :className="{
                        wrapper: 'd-flex align-items-center flex-shrink-0 me-2',
                        label: 'me-3',
                    }"
                />
                <button
                    type="button"
                    class="d-flex align-items-center align-self-start justify-content-center btn btn-sm btn-outline-primary me-2"
                    :disabled="props.isDisabled"
                    @click="emit('click:send-whatsapp')"
                >
                    <IconWhatsApp
                        width="16"
                        height="16"
                        class="me-2"
                    />
                    {{ t('cart.whatsapp') }}
                </button>
                <button
                    type="button"
                    class="btn btn-sm btn-outline-primary align-self-start text-uppercase"
                    :disabled="props.isDisabled"
                    @click="emit('click:send-sms')"
                >
                    {{ t('cart.sms') }}
                </button>
            </div>

            <div class="d-flex">
                <a
                    class="position-relative link text-decoration-none pe-4 fs-7 me-2"
                    href="#"
                    @click.prevent="toggleEmail"
                >
                    {{ t('cart.send_email') }}
                    <span
                        class="icon material-icons"
                        :class="!isVisibleEmail ? 'to-bottom' : 'to-top'"
                    >
                        <span class="material-symbols-outlined"> chevron_right </span>
                    </span>
                </a>

                <p class="fs-7 mb-0">
                    {{ t('cart.sms_left', smsCount) }}
                    <a
                        href="/billing/sms"
                        target="_blank"
                    >
                        {{ t('cart.buy_more') }}
                    </a>
                </p>
            </div>

            <div
                class="d-flex mt-3"
                v-if="isVisibleEmail"
            >
                <CustomInput
                    v-model:value="email"
                    :disabled="props.isDisabled"
                    :label="t('cart.email')"
                    :error="props.errors?.email"
                    :className="{
                        wrapper: 'd-flex align-items-center flex-shrink-0 me-2',
                        label: 'me-3',
                    }"
                />
                <button
                    type="button"
                    class="btn btn-primary btn-sm align-self-start"
                    @click="emit('click:send-email')"
                    :disabled="props.isDisabled"
                >
                    {{ t('cart.send') }}
                </button>
            </div>
        </div>
        <div class="left d-flex flex-column align-items-end ms-auto">
            <NativeSelect
                :className="{ wrapper: 'd-flex align-items-center flex-shrink-0 mb-3', label: 'me-3', select: '' }"
                :label="t('cart.lifetime')"
                :options="lifetimeOptions"
                :disabled="props.isDisabled"
                v-model:value="lifetime"
            />
            <button
                type="button"
                class="btn btn-primary btn-sm"
                :disabled="props.isDisabled"
                @click="emit('click:get-link')"
            >
                {{ t('cart.get_link') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

import { computed, ref, defineModel } from 'vue';
import CustomInputPhone from '@/common/components/inputs/CustomInputPhone.vue';
import CustomInput from '@/common/components/inputs/CustomInput.vue';
import IconWhatsApp from '@/common/components/icons/IconWhatsApp.vue';
import NativeSelect from '@/common/components/inputs/NativeSelect.vue';
import commonStore from '@/common/commonStore';

const props = defineProps({
    isDisabled: Boolean,
    errors: {
        type: Object,
        default: () => ({}),
    },
});

const phone = defineModel('phone');
const email = defineModel('email');
const lifetime = defineModel('lifetime');

const emit = defineEmits(['click:send-email', 'click:get-link', 'click:send-whatsapp', 'click:send-sms']);

const LIFETIME_DAYS = 7;
const lifetimeOptions = [];

for (let index = 0; index <= LIFETIME_DAYS; index++) {
    lifetimeOptions.push({
        name: t(`cart.lifetime_days`, { count: index }),
        id: index,
    });
}

const isVisibleEmail = ref(false);

const smsCount = computed(() => commonStore.state.smsCount);

const toggleEmail = () => {
    isVisibleEmail.value = !isVisibleEmail.value;
};
</script>

<style scoped>
.left {
    min-width: 28%;
}

.icon {
    position: absolute;
    transition: transform 0.15s;
    top: -2px;
    right: 0;
}

.to-bottom {
    transform: rotate(90deg);
}

.to-top {
    transform: rotate(-90deg);
    transition: transform 0.15s;
}
</style>
