<template>
    <div :class="props.className?.wrapper">
        <label
            v-if="props.label"
            :for="id"
            class="col-form-label-sm py-0"
            :class="[props.className?.label, { 'label-required': props.required }]"
        >
            {{ props.label }}
        </label>
        <div
            class="d-flex align-items-center input-number-no-arrows"
            :class="props.className?.inputWrapper"
        >
            <input
                v-number="props.inputConfig"
                v-model.number="inputModel"
                type="text"
                :id="id"
                class="min-width-60 max-width-80 input form-control form-control-sm"
                :class="{ 'is-invalid': props.errorInput }"
                :disabled="props.disabled"
            />
            <select
                v-if="selectModel"
                v-model="selectModel"
                class="select min-width-60 max-width-80 form-select form-select-sm ms-1"
                :disabled="props.disabled"
                :class="[props.className?.select, { 'input-error': props.errorSelect }]"
            >
                <option
                    :key="option[props.valueKey]"
                    :value="option[props.valueKey]"
                    v-for="option in props.options"
                >
                    {{ option.name }}
                </option>
            </select>
            <CustomCheckbox
                :className="{ wrapper: [props.className?.checkbox, 'ms-2'] }"
                v-if="checkboxModel !== null"
                v-model:value="checkboxModel"
                :label="props.checkboxLabel"
                :error="props.errorCheckbox"
            />
        </div>
    </div>
    <div
        v-if="hasError"
        :class="props.className?.wrapper"
    >
        <div :class="props.className?.label" />
        <p
            v-if="hasError"
            :class="props.className?.inputWrapper"
            class="color-error small my-1"
        >
            <span v-if="props.errorInput">
                {{ `${props.errorInput}${props.errorSelect || props.errorCheckbox ? ', ' : ''}` }}
            </span>
            <span v-if="props.errorSelect">
                {{ props.errorSelect }}
            </span>
            <span v-if="props.errorCheckbox">
                {{ props.errorCheckbox }}
            </span>
        </p>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import stringHelper from '@/common/helpers/stringHelper';
import CustomCheckbox from '@/common/components/inputs/CustomCheckbox';

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    checkboxLabel: {
        type: String,
        default: '',
    },
    options: {
        type: Array,
        default: () => [],
    },
    inputValue: {
        type: [String, Number],
        default: '',
    },
    checkboxValue: {
        type: [Boolean, null],
        default: null,
    },
    selectValue: {
        type: [String, Number],
        default: '',
    },
    className: {
        type: Object,
    },
    errorInput: {
        type: [String, Boolean],
        default: false,
    },
    errorSelect: {
        type: [String, Boolean],
        default: false,
    },
    errorCheckbox: {
        type: [String, Boolean],
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    returnKey: {
        type: String,
        default: '',
    },
    valueKey: {
        type: String,
        default: 'id',
    },
    inputConfig: {
        type: Object,
        default: () => ({
            point: false,
            minus: false,
        }),
    },
});

const id = stringHelper.getRandomUUID();

const emit = defineEmits(['update:inputValue', 'update:selectValue', 'update:checkboxValue']);

const inputModel = computed({
    get() {
        return props.inputValue;
    },
    set(value) {
        emit('update:inputValue', value);
    },
});

const selectModel = computed({
    get() {
        return props.selectValue;
    },
    set(value) {
        emit('update:selectValue', props.returnKey ? value[props.returnKey] : value);
    },
});


const checkboxModel = computed({
    get() {
        return props.checkboxValue;
    },
    set(value) {
        emit('update:checkboxValue', value);
    },
});

const hasError = computed(
    () =>
        typeof props.errorInput === 'string' ||
        typeof props.errorSelect === 'string' ||
        typeof props.errorCheckbox === 'string',
);
</script>

<style scoped>
.select:not(:disabled) {
    cursor: pointer;
}
</style>
