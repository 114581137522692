<template>
    <Modal
        ref="root"
        sizeClass="max-width-825"
    >
        <template v-slot:title>
            {{ title }}
        </template>
        <template v-slot:title-action>
            <div v-if="!linkForShare">
                <span
                    v-if="isLoading.history"
                    class="material-icons align-bottom me-1 rotating"
                >
                    autorenew
                </span>
                <a
                    class="text-decoration-none fs-8 me-3"
                    href="#"
                    @click.prevent="toggleHistory"
                >
                    {{ t('cart.history_load') }}
                </a>
                <a
                    class="text-decoration-none fs-8"
                    href="#"
                    @click.prevent="clearCart"
                >
                    {{ t('cart.clear') }}
                </a>
            </div>
        </template>
        <template #empty_body>
            <div class="px-3">
                <div
                    v-if="linkForShare"
                    class="d-flex py-3"
                >
                    <CustomInput
                        :value="linkForShare"
                        class="me-3 w-100"
                    />
                    <button
                        type="button"
                        class="btn btn-sm btn-light d-flex"
                        @click="copyText(linkForShare)"
                    >
                        <span class="material-icons fs-5 me-2"> content_copy </span>
                        {{ t('cart.copy') }}
                    </button>
                </div>
                <div
                    v-else-if="!linkForShare"
                    class="small"
                >
                    <HistoryTable
                        v-if="isShowHistory"
                        class="my-3"
                        :history="history"
                        :can-go-prev="canGoPrev"
                        :can-go-next="canGoNext"
                        :prev-loading="isLoading.prev"
                        :next-loading="isLoading.next"
                        @update:history-prev="loadHistoryPrev"
                        @update:history-next="loadHistoryNext"
                        @update:cart-list="selectHistoryItem"
                    />

                    <div
                        v-if="!apartments.length"
                        class="d-flex align-items-center justify-content-center p-5"
                    >
                        <p class="m-0">
                            {{ t('cart.empty') }}
                        </p>
                    </div>

                    <div
                        v-else
                        class="mt-3"
                    >
                        <PriceSettings
                            v-model:begin-date="beginDate"
                            v-model:end-date="endDate"
                            v-model:guestsCount="guestsCount"
                            v-model:extra-charge="extraCharge"
                            v-model:extra-charge-type="extraChargeType"
                            :is-disabled="isDisabledControls"
                            :errors="errors"
                            @update:begin-date="updateBeginDate"
                            @update:end-date="updateEndDate"
                            @update:guests-count="updateGuests"
                            @update:extra-charge="updateExtraCharge"
                        />

                        <ApartmentsList
                            :apartments="apartments"
                            :errors="errors"
                            :is-disabled="isDisabledControls"
                            @update:amount="onAmountUpdated"
                            @remove:apartment="remove"
                        />
                    </div>

                    <div
                        v-if="errors.items"
                        class="alert alert-danger p-2 mt-2"
                    >
                        {{ errors.items }}
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <template v-if="apartments.length">
                <UserActions
                    v-if="!linkForShare"
                    v-model:phone="phone"
                    v-model:email="email"
                    v-model:lifetime="lifetime"
                    :errors="errors"
                    :is-disabled="isDisabledControls"
                    @click:send-email="send"
                    @click:send-sms="send"
                    @click:get-link="getLink"
                    @click:send-whatsapp="getLink(true)"
                />
                <button
                    v-else
                    type="button"
                    class="btn btn-sm btn-primary ms-auto"
                    @click="root.close"
                >
                    {{ t('cart.close') }}
                </button>
            </template>
        </template>
    </Modal>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/common/components/Modal.vue';
import HistoryTable from '@/common/components/BaseCart/HistoryTable.vue';
import ApartmentsList from '@/common/components/BaseCart/ApartmentsList.vue';
import PriceSettings from '@/common/components/BaseCart/PriceSettings.vue';
import CustomInput from '@/common/components/inputs/CustomInput.vue';
import UserActions from '@/common/components/BaseCart/UserActions.vue';
import dateHelper from '@/common/helpers/dateHelper';
import emitter from '@/common/emitter';
import api from '@/common/api';
import amplitude from '@/common/amplitude';
import commonStore from '@/common/commonStore';
import useToast from '@/common/api/useToast';
import stringHelper from '@/common/helpers/stringHelper';

const { t } = useI18n();
const { show } = useToast();

const date = new Date();

const root = ref(null);
const isLoading = reactive({});
const errors = ref({});

const history = ref([]);
const currentHistoryPage = ref(1);
const totalHistoryPages = ref(null);
const isCartHistoryVisible = ref(false);
const isHistoryLoaded = ref(false);

const linkForShare = ref(null);

const phone = ref(null);
const email = ref(null);
const beginDate = ref(null);
const endDate = ref(null);
const apartments = ref([]);
const lifetime = ref(0);
const guestsCount = ref(1);
const extraCharge = ref(0);
const extraChargeType = ref('percent');

const title = computed(() => (linkForShare.value ? t('cart.title_link') : t('cart.title')));
const isShowHistory = computed(() => isCartHistoryVisible.value && isHistoryLoaded.value);
const canGoNext = computed(() => currentHistoryPage.value < totalHistoryPages.value);
const canGoPrev = computed(() => currentHistoryPage.value > 1);

const isSetDates = computed(() => beginDate.value && endDate.value);
const apartmentIds = computed(() => apartments.value.map((apartment) => apartment.id));

const isDisabledControls = computed(() => {
    return Object.keys(isLoading).some((key) => isLoading[key]);
});

const copyText = (text) => {
    stringHelper.copyText(text);
};

const setShareMode = (link) => {
    linkForShare.value = link;
    copyText(link);
};

const setErrors = (err) => {
    for (let key in err) {
        errors.value[key] = err[key][0];
    }
};

const resetErrors = () => {
    errors.value = {};
};

const clearCart = () => {
    guestsCount.value = 1;
    extraCharge.value = 0;
    extraChargeType.value = 'percent';
    email.value = null;
    phone.value = null;
    lifetime.value = 0;
    unselectAll();
};

const toggleHistory = () => {
    if (!isHistoryLoaded.value) {
        isCartHistoryVisible.value = true;
        loadHistory();
    } else {
        isCartHistoryVisible.value = !isCartHistoryVisible.value;
    }
};

const loadHistoryPrev = () => {
    if (currentHistoryPage.value > 1) {
        isLoading.prev = true;
        loadHistory(currentHistoryPage.value - 1);
    }
};

const loadHistoryNext = () => {
    isLoading.next = true;
    loadHistory(currentHistoryPage.value + 1);
};

const updateApartmentAmount = ({ apartmentId, price }) => {
    const updatedApartment = apartments.value.find((apartment) => apartment.id === apartmentId);
    if (updatedApartment && !updatedApartment.userUpdated) {
        updatedApartment.amount = Math.trunc(price / dateHelper.daysDiff(beginDate.value, endDate.value));
    }
};

const updateBeginDate = ({ date }) => {
    if (date) {
        beginDate.value = date;
        endDate.value = dateHelper.calculateDateEnd(date, endDate.value ? endDate.value : null);
    }
    loadAllPrices();
};

const updateEndDate = ({ date }) => {
    if (date) {
        endDate.value = date;
        beginDate.value = dateHelper.calculateDateBegin(beginDate.value ? beginDate.value : null, date);
    }
    loadAllPrices();
};

const updateGuests = (value) => {
    guestsCount.value = value;
    loadAllPrices();
};

const updateExtraCharge = ({value, type}) => {
    extraCharge.value = value;
    extraChargeType.value = type;
    loadAllPrices();
};

const onAmountUpdated = (id) => {
    apartments.value = apartments.value.map((apartment) => {
        if (apartment.id === id) {
            apartment.userUpdated = true;
        }
        return apartment;
    });
};

const unselect = (id) => {
    apartments.value = apartments.value.filter((apartment) => apartment.id !== id);
};

const unselectAll = () => {
    apartments.value = [];
    emitter.emit('unSelectAllApartments');
};

const remove = (id) => {
    unselect(id);
    emitter.emit('unSelectApartment', {
        from: 'cart',
        id: id,
        count: apartments.value.length,
    });
};

const selectHistoryItem = (item) => {
    amplitude.track(amplitude.EVENT.BASKET_HISTORY_ITEM_APPLY);
    beginDate.value = dateHelper.parseDate(item.source.begin_date);
    endDate.value = dateHelper.parseDate(item.source.end_date);
    email.value = item.source.email;
    phone.value = item.source.phone;

    if (item.source.guests_count) {
        guestsCount.value = item.source.guests_count;
    }

    if (item.source.extra_charge) {
        extraCharge.value = item.source.extra_charge;
    }

    if (item.source.extra_charge_type) {
        extraChargeType.value = item.source.extra_charge_type;
    }

    unselectAll();

    apartments.value = item.source.items.map((item) => {
        return {
            id: item.apartment_id,
            title: item.apartment_title,
            amount: item.amount,
            userUpdated: item.is_special_amount,
        };
    });

    loadAllPrices();
};

const loadPrice = async (id) => {
    const begin = dateHelper.formatDateString(beginDate.value);
    const end = dateHelper.formatDateString(endDate.value);
    isLoading.price = true;

    try {
        const data = await api.get({
            url: `apartments/${id}/price`,
            query: {
                begin_date: begin,
                end_date: end,
                extra_charge: extraCharge.value,
                guests_count: guestsCount.value,
                extra_charge_type: extraChargeType.value,
            },
        });
        updateApartmentAmount({
            apartmentId: id,
            price: data.price,
        });
    } catch {
        //
    } finally {
        isLoading.price = false;
    }
};

const loadAllPrices = () => {
    if (!isSetDates.value) {
        return;
    }
    apartmentIds.value.forEach((id) => {
        loadPrice(id);
    });
};

const loadHistory = async (page = 1) => {
    if (!isHistoryLoaded.value) {
        isLoading.history = true;
    }
    try {
        const data = await api.get({
            url: 'carts/history',
            query: {
                page,
            },
        });
        history.value = data.baskets;
        currentHistoryPage.value = data.pagination.current_page;
        totalHistoryPages.value = data.pagination.total_pages;
        isHistoryLoaded.value = true;
    } catch {
        //
    } finally {
        isLoading.history = false;
        isLoading.prev = false;
        isLoading.next = false;
    }
};

const prepareData = (withContacts = false) => {
    let cart = {
        begin_date: beginDate.value ? dateHelper.formatDateString(beginDate.value) : null,
        end_date: endDate.value ? dateHelper.formatDateString(endDate.value) : null,
        items: [],
        lifetime: lifetime.value,
        extra_charge: extraCharge.value,
        extra_charge_type: extraChargeType.value,
        guests_count: guestsCount.value,
    };

    if (withContacts) {
        cart.email = email.value;
        cart.phone = phone.value;
        // TODO: Поменять формат отправки после правок на бэке
        // cart.phone = stringHelper.getDigitPhone(phone.value);
    }

    apartments.value.forEach((apartment) => {
        cart.items.push({
            apartment_id: apartment.id,
            apartment_title: apartment.title,
            amount: apartment.amount,
            is_special_amount: apartment.userUpdated,
        });
    });

    return { cart };
};

const getLink = async (isWhatsApp = false) => {
    resetErrors();

    const params = prepareData(isWhatsApp);
    // TODO: Костыль, т.к. нет валидации под кейс с whatsapp
    if (isWhatsApp && !params.cart.phone) {
        errors.value.phone = t('cart.need_phone');
        return;
    }
    isLoading.gettingLink = true;

    try {
        const { basket } = await api.post({
            url: 'carts/copy_link',
            data: params,
        });
        clearCart();
        if (isWhatsApp) {
            // TODO: Продолжение костыля
            if (basket.whatsapp_url) {
                amplitude.track(amplitude.EVENT.BASKET_SEND, { action: 'whatsapp' });
                window.open(basket.whatsapp_url);
                root.value.close();
            } else {
                errors.value.phone = t('cart.whatsapp_error');
            }
        } else {
            amplitude.track(amplitude.EVENT.BASKET_GETLINK);
            setShareMode(basket.short_url);
        }
        loadHistory();
    } catch (err) {
        if (err.isValidation) {
            setErrors(err.errors);
        }
    } finally {
        isLoading.gettingLink = false;
    }
};

const send = async () => {
    resetErrors();
    isLoading.sendCart = true;
    const params = prepareData(true);
    try {
        await api.post({
            url: 'carts',
            data: params,
        });
        const trackActions = [];
        if (params.cart.email) {
            trackActions.push('email');
        }
        if (params.cart.phone) {
            trackActions.push('sms');
            await commonStore.dispatch('loadSMSCount');
        }
        show({ text: t('cart.cart_is_send') });
        amplitude.track(amplitude.EVENT.BASKET_SEND, { action: trackActions.join(', ') });
        clearCart();
        loadHistory();
        root.value.close();
    } catch (err) {
        if (err.isValidation) {
            setErrors(err.errors);
        }
    } finally {
        isLoading.sendCart = false;
    }
};

const open = (size = 'default') => {
    beginDate.value ??= dateHelper.parseDate(date);
    endDate.value ??= dateHelper.addToDate(date, [+1]);
    errors.value = {};
    linkForShare.value = null;

    root.value.open(size);
};

watch(isShowHistory, (val) => {
    if (val) {
        amplitude.track(amplitude.EVENT.BASKET_HISTORY_VIEW);
    }
});

watch(email, () => {
    errors.value.email = null;
});

watch(phone, () => {
    errors.value.phone = null;
});

watch(apartments, () => {
    errors.value.items = null;
});

watch(beginDate, () => {
    errors.value.begin_date = null;
});

watch(endDate, () => {
    errors.value.end_date = null;
});

onMounted(() => {
    emitter.on('selectApartment', (event) => {
        if (apartments.value.find((apartment) => apartment.id == event.apartment.id)) {
            return;
        }
        if (isSetDates.value) {
            event.apartment.amount = null;
        }
        event.apartment.userUpdated = false;
        apartments.value = [...apartments.value, event.apartment];
        if (isSetDates.value) {
            loadPrice(event.apartment.id);
        }
    });

    emitter.on('unSelectApartment', (event) => {
        if (event.from == 'cart') {
            return;
        }
        unselect(event.id);
    });

    emitter.on('updateFilterDates', (date) => {
        beginDate.value = date.beginDate;
        endDate.value = date.endDate;
        loadAllPrices();
    });
});

defineExpose({
    open,
});
</script>
<style scoped></style>
