<template>
  <div
    class="form-group d-flex"
    :class="props.className?.wrapper"
  >
    <input
      class="form-check-input cursor-pointer my-1"
      type="checkbox"
      :id="id"
      :true-value="!props.inverseValue"
      :false-value="!!props.inverseValue"
      v-model="inputValue"
      :disabled="props.disabled"
      :value="props.returnValue"
      :class="{
        'is-invalid': props.error,
        'me-2': props.label
      }"
    >
    <label
      v-if="props.label"
      class="form-check-label cursor-pointer small"
      :for="id"
      v-html="props.label"
    />
  </div>
</template>

<script setup>
import {computed} from 'vue';
import stringHelper from '@/common/helpers/stringHelper';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  className: {
    type: Object,
  },
  value: {
    type: [Boolean, Array],
    required: true,
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  returnValue: {
    type: [String, Number],
  },
  inverseValue: {
    type: Boolean,
    default: false,
  }
});

const id = stringHelper.getRandomUUID();

const emit = defineEmits(['update:value']);

const inputValue = computed({
    get() {
      return props.value;
    },
    set(value) {
      emit('update:value', value);
    },
  },
);

</script>

<style scoped>

</style>
