<template>
    <div class="modal" tabindex="-1" ref="root">
        <div class="modal-dialog" :class="[sizeClass ? sizeClass : '', needScroll ? 'modal-dialog-scrollable' : '', centered ? 'modal-dialog-centered' : '']">
            <div class="modal-content">
                <div v-if="$slots['title']" class="modal-header">
                    <h5 class="modal-title"><slot name="title"></slot></h5>
                    <div v-if="$slots['title-action']" class="ms-auto me-3">
                        <slot name="title-action"></slot>
                    </div>
                    <button type="button" class="btn-close fs-8" :class="[$slots['title-action'] ? 'ms-0' : '']" data-bs-dismiss="modal"></button>
                </div>
                <div v-if="$slots.body" class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div v-if="$slots.empty_body">
                    <slot name="empty_body"></slot>
                </div>
                <div
                    v-if="leftButtons.length || rightButtons.length"
                    class="modal-footer justify-content-between"
                >
                    <button
                        v-for="button in leftButtons"
                        :key="button.id"
                        type="button"
                        class="btn btn-sm"
                        :class="button.class"
                        :disabled="button.disabled"
                        @click="$emit('clickButton', button)"
                    >{{ button.title}}</button>

                    <button
                        v-for="(button, index) in rightButtons"
                        :key="button.id"
                        type="button"
                        class="btn btn-sm"
                        :class="[button.class, index === 0 ? 'ms-auto' : '']"
                        :disabled="button.disabled"
                        @click="$emit('clickButton', button)"
                    >{{ button.title}}</button>
                </div>

                <div
                    v-else-if="$slots.footer"
                    class="modal-footer justify-content-start"
                >
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    const bootstrap = require('bootstrap');
    import 'bootstrap/dist/css/bootstrap.min.css';
    import { ref } from 'vue';
    import emitter from '@/common/emitter';
    export default {
        name: 'Modal',
        props : {
            leftButtons: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            rightButtons: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            sizeClass : {
                type: String,
                default : ''
            },
            needScroll: {
                type: Boolean,
                default: false
            },
            centered: {
                type: Boolean,
                default: true
            }
        },
        emits : [
            'clickButton', 'close'
        ],
        setup(props, { emit }) {
            const root = ref(null);
            let modal = null;

            const open = () => {
                emitter.emit('closeModals');
                modal = new bootstrap.Modal(root.value);
                root.value.addEventListener('hidden.bs.modal', onHiddenModal);
                root.value.addEventListener('hide.bs.modal', onHideModal);
                emitter.on('closeModals', close);
                modal.show();
                window.isModal = true;
            };

            const onHiddenModal = () => {
                window.isModal = false;
                emit('close', {});
            }

            const onHideModal = () => {
                emitter.emit('escape');
            }

            const close = () => {
                if (modal) {
                    modal.hide();
                    root.value.removeEventListener('hidden.bs.modal', onHiddenModal);
                    root.value.removeEventListener('hide.bs.modal', onHideModal);
                    emitter.off('closeModals', close);
                }
            }

            return {
                root,
                open,
                close,
            }
        }
    }
</script>
