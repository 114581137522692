export const SETTINGS_RESTRICTION_TAB_VIDEO = 'https://www.youtube.com/embed/rFuKsIErbEk';
export const Y_KASSA_PAYMENTS_MANUAL = 'https://realtycalendar.omnidesk.ru/knowledge_base/item/309240';
export const MONETA_PAYMENTS_MANUAL = 'https://realtycalendar.omnidesk.ru/knowledge_base/item/309167';
export const AFFILIATE_PROGRAM = 'https://realtycalendar.omnidesk.ru/knowledge_base/item/309232';
export const BONUS_POLICY = 'https://realtycalendar.ru/legal/bonuses.pdf';
export const ADDITIONAL_GUEST_LINK = 'https://realtycalendar.omnidesk.ru/knowledge_base/item/309206';
// убрать при выполнении таска на удаление wallet 1
export const WALLET_1_PAYMENTS_MANUAL = 'https://help.realtycalendar.ru/hc/ru/articles/360016059111-%D0%9D%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D1%8B';
export const USER_AGREED='https://new.realtycalendar.ru/obrabotka-personalinyx-dannyx';
export const PRIVACY_AGREED='https://new.realtycalendar.ru/soglasie-personalinye-dannyx';
export const ADS_AGREED='https://new.realtycalendar.ru/soglasie-prymye-kontakty';
export const TG_SUPPORT = 'https://t.me/RealtyCalendar_support_bot';
export const DEPOSITS_ON_LINK = 'https://new.realtycalendar.ru/deposit';
