<template>
    <div>
        <table class="table table-striped table-sm">
            <thead>
                <tr>
                    <th scope="col">{{ t('cart.history_table_date') }}</th>
                    <th scope="col">{{ t('cart.history_table_phone') }}</th>
                    <th scope="col">{{ t('cart.history_table_email') }}</th>
                    <th scope="col">{{ t('cart.history_table_source') }}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="item in history"
                    :key="item.id"
                >
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.source.phone }}</td>
                    <td>{{ item.source.email }}</td>
                    <td>{{ item.source.from }}</td>
                    <td>
                        <a
                            class="link fs-8"
                            href="#"
                            @click.prevent="emit('update:cartList', item)"
                        >
                            {{ t('cart.history_table_select') }}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row mt-2">
            <div class="col">
                <div class="d-flex align-items-center justify-content-start">
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        :disabled="!canGoPrev"
                        @click="emit('update:historyPrev')"
                    >
                        ←
                    </button>
                    <span
                        v-if="prevLoading"
                        class="material-icons align-bottom me-1 rotating"
                    >
                        autorenew
                    </span>
                </div>
            </div>
            <div class="col">
                <div class="d-flex align-items-center justify-content-end">
                    <span
                        v-if="nextLoading"
                        class="material-icons align-bottom me-1 rotating"
                    >
                        autorenew
                    </span>
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        :disabled="!canGoNext"
                        @click="emit('update:historyNext')"
                    >
                        →
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

defineProps({
    history: {
        type: Object,
        required: true,
    },
    canGoPrev: Boolean,
    canGoNext: Boolean,
    prevLoading: Boolean,
    nextLoading: Boolean,
});

const emit = defineEmits(['update:cartList', 'update:historyPrev', 'update:historyNext'])
</script>

<style scoped></style>
