<template>
    <div class="position-relative">
        <div>
            <div
                v-for="apartment in apartments"
                :key="apartment.id"
                class="list-item d-flex align-items-center py-3"
                :class="{'disabled': isDisabled}"
            >
                <p class="mb-0 me-auto">
                    {{ apartment.title }}
                </p>
                <div class="controls d-flex align-items-center">
                    <CustomInput
                        class="me-2 max-width-95"
                        :disabled="isDisabled"
                        :error="errors.amount"
                        v-model:value="apartment.amount"
                        @input="emit('update:amount', apartment.id)"
                    />
                    <span class="ps-0 pe-2 align-self-center">
                        {{ t('cart.amount') }}
                    </span>
                    <button
                        type="button"
                        class="ms-auto btn-close fs-9"
                        :disabled="isDisabled"
                        @click="emit('remove:apartment', apartment.id)"
                    />
                </div>
            </div>
        </div>
        <BasePreloader v-if="isDisabled"/>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import CustomInput from '@/common/components/inputs/CustomInput.vue';
import BasePreloader from '@/common/components/Preloader/BasePreloader.vue';

const { t } = useI18n();

defineProps({
    apartments: {
        type: Array,
        required: true,
    },
    errors: {
        type: Object,
        default: () => ({}),
    },
    isDisabled: Boolean,
});

const emit = defineEmits(['update:amount', 'remove:apartment']);
</script>

<style scoped>
.max-width-95 {
    max-width: 95px;
}
.controls {
    min-width: 28%;
}
.list-item:not(:last-child) {
    border-bottom: 1px solid var(--bs-border-color);
}
</style>
